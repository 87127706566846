@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');
/*CARD TASK CLIENT CSS*/




/*
 * @component CardStepTracker
 * @description estilos del card 
 */
.card-shadow {
    background: #FFFFFF;
    border-radius: 10px !important;
    box-shadow: 0px 30px 60px rgba(32, 56, 85, 0.15);

}

/*
 * @component CardSaldosGraph
 * @description Borde del card
 */

.card-saldos-dashboard {
    border-radius: 10px !important;
    background: #f26513;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
}

/*
 * @component CardSaldosGraph
 * @description Borde del card
 */

.card-saldos-dashboard div,
.card-saldos-dashboard canvas {
   /* max-width: 100% !important;*/
}


.card-saldos-dashboard .ant-card-body {
   padding-top: 0.5rem !important;
}

/*
 * @description Elimina el padding para que el contenido se ajuste mejor
 */

.card-saldos-dashboard .ant-card-body .ant-typography {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

/*
 * @description Estilos de la fuente del HEAD del card
 */

.card-saldos-dashboard .ant-card-head {
    border: none;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: white;
}

/*
 * @component CardOperations
 * @description Asigna el fondo transparente
 */

.card-venta-mensual-dashboard {
    background: transparent !important;
}

/*
 * @description Estilos del Head del Card
 */
.card-venta-mensual-dashboard .ant-card-head {
    border: none;
    padding: 0 1rem;
    min-height: 15px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #262631;
}

/*
 * @description Estilos del Head del Card
 */
.card-venta-mensual-dashboard .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    padding: 0 !important
}

/*
 * @description Modificacion al padding del Body del Card
 */
.card-venta-mensual-dashboard .ant-card-body {
    padding: 0 1rem;
}

/*
 * @description Modificacion al padding del Meta del Card
 */
.card-venta-mensual-dashboard .ant-card-body .ant-card-meta {
    padding: 1rem 0;
}

/*
 * @description Estilos de fuente del Meta title del Card
 */
.card-venta-mensual-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    color: #2E2E3A;
}

/*
 * @description Estilos de fuente del Meta descripcion del Card
 */
.card-venta-mensual-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #656575;
}

/*
 * @description Estilos del texto de porcentaje e icono  rojos
 */
.ventas-down, .ventas-down svg {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #FD4438;
}

/*
 * @description Estilos del texto de porcentaje e icono verdes
 */
.ventas-up, .ventas-up svg {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #24CA49;
}

/*
 *
 * @description Card que se encuentra dentro del card de Operations,Asignacion de estilos del card
 */
.card-venta-mensual-dashboard-inner {
    border-radius: 16px !important;
    height: 225px;
}

/*
 * @description Modifacion de espacios y tamaño del head del card
 */
.card-venta-mensual-dashboard-inner .ant-card-head {
    padding: 0 1rem;
    height: 5px;
}

/*
 * @description Modifacion de espacios del body para que no abarque tanto espacio
 */
.card-venta-mensual-dashboard-inner .ant-card-body {
    padding: 0 1rem;
}

/*
 * @description Estilos del texto del card
 */
.card-venta-mensual-dashboard-inner-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #27272E;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
}

/*
 * @description Estilos del texto del card para el progress bar
 */
.card-venta-mensual-dashboard-progress-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #84859E;
    margin: 2px 0px;
}

/*
 *
 * @description Contenedor del icono del Tools del card
 */
.box-icon-rounded {
    background: rgba(0, 205, 152, 0.2);
    border-radius: 8px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    justify-self: center;
    vertical-align: middle;
}



@media screen and (min-width:1800px) {
    .col-list-task-margin {
        max-width: 450px;
    }

    .card-task-name, .card-task {
        display: block;
        margin: 0 auto 10px auto !important;
    }
}


/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo {
    border-radius: 10px !important;
    min-height: 219px;
}

/*
 * @component CardSaldo
 * @description el titulo del Card
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-title {
    color: #858997;
    font-weight: 600;
    font-size: 1.375rem;
    margin-bottom: 1.2rem;
}

/*
 * @component CardSaldo
 * @description estilos para el monto total de las cuentas
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 30px;
    color: #000;
    margin-bottom: 15px;
}

/*
 * @component CardSaldo
 * @description estilos del small
 */
.card-saldo .card-saldo-main-meta small {
    font-size: 14px;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: 600;
    font-size: 12px;
    color: #858997;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: 600;
    font-size: 12px;
    color: #0047FF !important;
    margin-bottom: 20px;
}

/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards .react-multi-carousel-item {
    max-width: 387px;
}

/*
 * @component CardCuenta
 * @description Estilo para la tarjeta bancaria
 */
.card-cuenta {
    border-radius: 20px !important;
    margin-right: 1rem !important;
    min-height: 219px;
    width: 370px;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px !important;
}

.card-cuenta .btn-purple {
    background: rgba(169, 6, 245, 0.75);
    color: white;
    border: none;
}

/*
 * @component CardCuenta
 * @description Estilo para el nombre de la cuenta
 */
.card-cuenta .cuenta-nombre {
    font-size: 1.125rem;
    color: #FFFFFF;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo principal de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title {
    font-weight: bold;
    font-size: 1.875rem;
    color: #FFFFFF;
    margin-top: 0.9rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-description {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 0.5rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title small {
    font-size: 14px;
}

/*
 * @component CardGains
 * @description Estilo para los cards de profit y loss
 */
.card-gains {
    font-family: Inter;
    font-style: normal;
    max-height: 250px;
    width: 100%;
    border-radius: 16px !important;
}

/*
 * @component CardGains
 * @description Estilo para el titulo del card
 */
.card-gains .card-title {
    font-weight: 600;
    font-size: 16px;
    color: #27272E;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .text-paid {
    font-weight: normal;
    font-size: 14px !important;
    color: #84859E;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots {
    padding-right: 5px;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots svg {
    transform: rotate(90deg);
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .green-box {
    background: rgba(29, 210, 0, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box {
    background: rgba(229, 10, 10, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box svg,
.card-gains .green-box svg {
    width: 25px;
}

.card-project .ant-card-head,
.card-task-progress .ant-card-head,
.card-address .ant-card-head,
.card-utilidad .ant-card-head {
    padding: 0rem 1rem;
    border-bottom: 0;
    height: 20px;
}

.card-project .ant-card-body,
.card-task-progress .ant-card-body,
.card-address .ant-card-body {
    padding: 0 1rem 1rem 1rem;
}

.card-utilidad .ant-card-body {
    padding: 0px 1rem;
}

.card-task-progress {
    max-height: 270px;
}


.card-project {
    overflow: auto;
    margin-right:1rem ;
}

.card-project .ant-card-head-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-project-info {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #121212;
    opacity: 0.7;

}

.utilidad-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-account-purchases .ant-card-head {
    padding: 0 !important;
    max-height: 30px !important;
    border: none;
}

.card-account-purchases .ant-card-body {
    padding: 10px 0px 10px 10px !important;
}

/*
 * @component CardGains
 * @description Estilo para el Titulo del card
 */
.card-title-purple {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #5C54E0 !important;
}

/*
 * @component Text
 * @description Estilo para el icon del card
 */
.card-monto-gray {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #121212;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 32px;
}

/*
 * @component Tag
 * @description Estilo para el icon del card
 */
.tag-spot-vmi {
    background: #96D3FF !important;
    border: none !important;
    color: #0070F3 !important;
    width: 80px;
    font-size: 15px !important;
    font-weight: 600;
    text-align: center;
}

/*
 * @component CardProductEstatus
 * @description Estilo para el icon del card
 */
.card-estatus-text {
    font-family: Lato;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 15px;
    line-height: 2.24rem;
}

/*
 * @component CardProductEstatus
 * @description Estilo para el texto del card
 */
.card-estatus-cantidad {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #000000;


}

/*
 * @component CardProductVentas
 * @description Estilo para la cantidad del card
 */
.card-cantidad-blue {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #2D348C;
}

/*
 * @component CardFactura
 * @description Estilo para los botones del card
 */
.card-factura-button {
    display: flex !important;
    width: 28px !important;
    height: 28px !important;
    align-items: center;
    justify-content: center;
}

.card-factura-button svg {
    display: block !important;
}

/*
 * @component CardIncomeExpense
 * @description Estilo para los botones del card
 */

.card-income-expense-text {
    color: #222222 !important;
}

.card-income-expense-button {
    display: flex !important;
    width: 28px !important;
    height: 28px !important;
    align-items: center;
    justify-content: center;
}

.card-income-expense-button svg {
    display: block !important;
}

@media screen and (max-height:1024px) {
    .green-box svg, .red-box svg {
        width: 20px !important;
    }
    .green-box, .red-box{
        height: 38px!important;
        width: 40px!important;
    }

}

.card-customer-pm-compras .ant-card-body {
    padding: 0.75rem 0 0 0 !important;
}

.card-rs-blue{
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #2D348C;
}

.card-rs-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-rs-cantidad{
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #121212;
    margin-top: 4px;
}

/* ISYTech Web */

.card-rifa{
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.card-rifa.card-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
}

.card-image-sm{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    width: 100px;
    border-radius: 14px;
}

.card-rifa .title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}

.card-rifa .descripcion{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    height: 90px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 0.5rem;
}

.card-rifa .fecha{
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    display: block;
    height: 50px;
}

.card-rifa .div-index{
    background: #87D068;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    color: white;
    position: absolute;
    top: 170px;
    left: 10px;
}