.list-precios{
    .ant-list-items{
        // max-height: 320px;
        max-height: 530px;
        overflow: auto;
        padding-right: 7px;
    }
}

.popup-list {
    z-index: 1000!important;
}

.not-scroll{
    overflow: hidden;
}


.button-whatsapp{
    color: white;
    background-color: #25D366;
    
    &:hover {
        
        background-color: white !important;
        border-color: #25D366;
        color: #25D366;
    } 
}