.carrito {

    .ant-drawer-content-wrapper {
        max-width: 500px !important;
        width: 100%!important;

    }

    .btn-whatsapp {
        background-color: #65D072;
        border: #65D072 2px solid;
        border-radius: 4px;
        // width: 100%;
    }
}