@import './variables.scss';
@import '../mixins';

$slider-width: 100vw;
$slider-height: 100vh;

#inicio {
    position: relative;
}


.slider {
    @include slider(100vw, 100vh);
}


.us {
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 80vh;


    @media (max-width: $lg) {
        height: unset;
    }


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-header {
        z-index: 4;

        display: block;
        width: fit-content;
        position: relative;
        top: 35px;

        @media (max-width: $lg) {
            padding: 20px;

        }

        &-title {
            font-family: 'Anton', sans-serif;
            margin-bottom: 0 !important;
            display: block;
            font-size: 50px;
            width: fit-content;

            @media (max-width: $lg) {
                font-size: 30px;
            }
        }

        &-image {
            height: 65px;

            @media (max-width: $lg) {
                height: unset;
                width: 100%;
            }
        }
    }

    &-content {
        z-index: 2;

        width: fit-content;
        display: flex;
        gap: 20px;

        @media (max-width: $lg) {
            flex-direction: column;
            padding: 20px;
        }
    }


    &-wrapper {
        z-index: 2;

        width: fit-content;
        text-align: end;
        padding-top: 20px;
    }

    &-description {
        z-index: 2;

        max-width: 350px;
        text-align: justify;
        font-size: 17px;
        font-weight: 500;

        @media (max-width: $lg) {
            max-width: unset !important;
        }

        &-image {
            max-height: 300px;

            @media (max-width: $lg) {
                max-height: unset !important;
                width: 100%;
            }
        }
    }

    .circle {
        position: absolute;
        top: -42%;
        left: -7%;
        width: 500px;
        z-index: 1;
        // width: ;
    }

    .dots {
        position: absolute;
        bottom: 0%;
        left: 0%;
        max-height: 200px;
        z-index: 0;
    }

    .escorpio {
        position: absolute;
        right: -13%;
        height: 70%;
        opacity: 0.15;
        z-index: 0;

        @media (max-width: $lg) {
            display: none;
        }
    }
}

.ribbon {
    background: #F26513;
    padding: 0 50px;

    display: flex;
    justify-content: space-between;
    overflow: hidden;
    // justify-content: center;
    align-items: center;

    @media (max-width: $md) {
        // font-size: 20px;
        flex-direction: column;
        gap: 12px;
        padding: 20px 50px;
    }


    &-logo {
        position: relative;
        overflow: hidden;
        // height: 100%;
        width: 150px;
        height: 100px;
        // background: white;

        &-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100px;
            transform: rotate(30deg);
        }

        @media (max-width: $lg) {
            display: none;
        }
    }

    &-title {
        color: #FFF;
        font-weight: bold;
        font-size: 48px;
        font-family: Anton, sans-serif;
        letter-spacing: 2px;
        text-align: center;

        @media (max-width: $lg) {
            font-size: 36px;
        }

        @media (max-width: $md) {
            font-size: 38px;
        }
    }

    &-button {
        border-radius: 0 !important;
        color: #F26513;
        border: none !important;
    }
}


.latest-sorteos {
    max-width: $lg;
    padding-top: 120px;
    margin-bottom: 80px;

    align-self: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    max-width: 1200px;

    &-title {
        text-align: center;
        font-family: Anton, sans-serif;

        .orange {
            color: #F26513;
        }
    }

    &-description {
        width: 100%;
        max-width: 600px;
        display: block;
        margin: 20px auto 0 auto;
        padding-bottom: 40px;
        font-size: 16px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .boton-evento {
        font-family: Anton, sans-serif;
        border-radius: 0;
        border: none;
    }

}


.ganadores{

    justify-content: center;
    width: 100%;
    margin-bottom: 100px;

    h1.ant-typography {
        @media (max-width: $md) {
            font-size: 34px;
            // flex-direction: column;
        }
    }

    &-header {

        max-width: 1200px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: auto;

        margin-bottom: 28px;
        padding: 12px;

        @media (max-width: $md) {
            // font-size: 38px;
            flex-direction: column;
        }
        // .button-sorteos{
        // }
    }
    
    .winners{
        // max-width: 1200px;
        width: 100%;
        margin: auto;
        .winners-slide {
            // width: 400px;
            height: 400px;
            object-fit: contain;

        }
        .ant-image {
            display: block;
            margin: auto;
            // object-fit: contain;
        }
        overflow: hidden;
    }
}