@import './Public/variables.scss';


@mixin slider($width, $height, $clip-path: false) {
    width: $width;
    height: $height;

    &-item {
        position: relative;
        width: $width;
        height: $height;
    }

    &-content {
        width: $width;
        height: $height;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        padding: 0 80px;

        @media (max-width: $md) {
            padding: 0 40px !important;
        }

        @media (max-width: $sm) {
            padding: 0 40px !important;
        }
    }

    &-shadow {
        width: $width;
        height: $height;

        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.4);
        @if ($clip-path) {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
        }
    }

    &-title {
        color: white !important;
        font-size: 60px !important;
        font-weight: 700 !important;
        margin-top: 0 !important;
        margin-bottom: 12px !important;

        @media (max-width: $sm) {
            font-size: 44px !important;
        }
    }

    &-description {
        color: white !important;
        font-weight: 400;
        font-size: 26px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        @media (max-width: $sm) {
            font-size: 18px !important;
        }
    }

    &-background {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        object-fit: cover;

        @if ($clip-path) {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
        }
    }

    &-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: calc(50% - 10px);
        padding: 0 30px;

        @media (max-width: $md) {
            padding: 0 10px !important;
        }
    }

    .slick-dots {
        position: absolute !important;
        bottom: 25px;
        z-index: 1;

        li {
            button:before {
                opacity: 0.4;
                color: $primary;
            }

            &.slick-active{
                button:before {
                    // opacity: 0.7;
                    // color: $primary;
                    opacity: 1 !important;
                }
            }

   
        }
    }
}
