// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// Palette
$primary: #F26513;
$accent: #40DDB6;

$text: #030303;
$text-accent: #F26513;
$text-alt: white; 

$primary-grandient: linear-gradient(90deg, #0B1620 13.89%, #163B5B 100%);
$primary-bg: white;
$secondary-bg: #474950;