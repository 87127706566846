@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');



html {
    overflow-x: hidden;
    overflow-y: overlay;
}



/*
* @component Tamaño de la barra de navegación
* @description Es el tamaño universal de la barra de navegación
*/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/*
* @component La bara de navegación en si, pero el desalizador
* @description CSS del deslizador de la barra de navegación
*/
::-webkit-scrollbar-thumb {
    background: #F26513;
    border-radius: 20px;
}


::-webkit-scrollbar-thumb:hover {
    background: #F26513;
}



/*-----------------------------------CLASES GLOBALES----------------------------------------*/

.border {
    border: solid 1px red;
}

.no-border {
    border: none !important;
}

.no-strong {
    font-weight: 300 !important;
}

.center {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Quita el border inferior de los elementos de la lista
 */
 .component-list {
    overflow-x: auto;
}

 .component-list .ant-list-header,
 .component-list .ant-list-items {
     min-width: 1000px;
 }
.component-list .ant-list-header,
.component-list .component-list-item {
    border: none !important;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Creae efecto de hover
 */

 .component-list-item .hover:hover {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
}

.sticky-redes {
    position: fixed;
    bottom: 48px;
    right: 12px;
    z-index: 1000;
}


@media screen and (min-width: 768px) {
    .md-center {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .md-flex-left {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
    }
}

.block-center {
    display: block;
    margin: auto;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.flex-column {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.flex-left {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.flex-column-left {
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.flex-right {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
}

.flex-right-top {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-start;
}

.flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-column-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.flex-left-column-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.flex-left-column-around {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
}

.flex-between {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.float-left {
    float: left;
}

.heigth-100 {
    height: 100% !important;
}

.p-0 {
    padding: 0 !important;
}

.pd-1 {
    padding: 1rem !important;
}

.pd-2 {
    padding: 2rem !important;
}

.pd-3 {
    padding: 3rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-05 {
    padding-top: 0.5rem !important;
}

.pt-2 {
    padding-top: 2rem !important;
}

.pt-3 {
    padding-top: 3rem !important;
}

.pt-4 {
    padding-top: 4rem !important;
}

.pl-05 {
    padding-left: .5rem !important;
}

.pl-1 {
    padding-left: 1rem !important;
}

.pl-2 {
    padding-left: 2rem !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-05 {
    padding-right: .5rem !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pr-2 {
    padding-right: 2rem !important;
}

.pb-1 {
    padding-bottom: 1rem !important;
}

.pb-2 {
    padding-bottom: 2rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.pb-4 {
    padding-bottom: 4rem !important;
}


.pr-3 {
    padding-right: 3rem !important;
}

.m-0 {
    margin: 0 !important;
}

.m-3 {
    margin: 3rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.mt-05 {
    margin-top: 0.5rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mt-4 {
    margin-top: 4rem !important;
}

.mt-5 {
    margin-top: 5rem !important;
}

.mb-05 {
    margin-bottom: 0.5rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mr-05 {
    margin-right: 0.5rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-center,
.text-center .ant-form-item-label {
    text-align: center !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-white {
    color: #FFF !important;
}

.text-gray {
    color: #858997 !important;
}

.text-gray-dark {
    color: #222222 !important;
}

.text-purple {
    color: #9D61F4 !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-12,
.font-12 * {
    font-size: 12px !important;
}

.font-13,
.font-13 * {
    font-size: 13px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-28 {
    font-size: 28px !important;
}

.width-100,
.width-100 .ant-upload {
    width: 100% !important;
}


.width-95 {
    width: 95%;
}

.bg-white {
    background: #FFFFFF;
}

.disabled-link {
    pointer-events: none;
}




/*Overriding Ant Design Styles*/

/**
@description Le quitamos la linea de los cards,  la que divide.
*/
.ant-card-head {
    border-bottom: none !important;
}


.heigth-100 {
    height: 100%;
}

.ant-input {
    border-radius: 8px !important;
}

.ant-select-selector{
    border-radius: 8px !important;
}

.ant-select-dropdown {
    border-radius: 8px !important;
}

$blue: #0047FF;
$purple: #722ed1;
$cyan: #13c2c2;
$green: #52c41a;
$magenta: #eb2f96;
$pink: #eb2f96;
$red: #f5222d;
$orange: #fa8c16;
$yellow: #fadb14;
$volcano: #fa541c;
$geekblue: #2f54eb;
$lime: #a0d911;
$gold: #faad14;


$buttonColors: (
    "blue": #0047FF,
    "purple": #722ed1,
    "cyan": #13c2c2,
    "green": #65CCCC,
    "magenta": #eb2f96,
    "pink": #eb2f96,
    "red": #f5222d,
    "orange": #fa8c16,
    "yellow": #fadb14,
    "volcano": #fa541c,
    "geekblue": #2f54eb,
    "lime": #a0d911,
    "gold": #faad14,
    "gray": #979797,
    "white": white
);

@each $name,
$color in $buttonColors {

    .text-#{$name} {
        color: $color;
    }

    .ant-btn-#{$name} {
        border-color: $color !important;
        background: $color !important;

        &:hover,
        &:focus {
            border-color: rgba($color, .6) !important;
            background: rgba($color, .6) !important;
        }

        &:active {
            border-color: rgba($color, .6) !important;
            background: rgba($color, .6) !important;
        }
    }



    .ant-btn-ghost-#{$name} {
        border-color: $color;
        background: transparent;
        color: $color;

        &:hover,
        &:focus {
            border-color: rgba($color, .6);
            color: rgba($color, .6);
            background: transparent;
        }

        &:active {
            border-color: rgba($color, .6);
            color: rgba($color, .6);
            background: transparent;
        }
    }

}