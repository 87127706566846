/*
 * @component Header 
 * @description Cambia el tamaño del header
 */
 .header {
    height: auto;
    min-height: 100px;
    padding: 0px!important;
    // background: #000;
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen (logo) del header
 */
.logo {
    height: 100px;
    min-height: 100px;
}


/*
 * @component Header 
 * @description Cambia el tamaño del logo del header
 */
 .logo img {
    height: 50px!important;
    width: 80%
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen para que se ajuste al sidebar
 * Si se mueve este estilo, se tiene que modificar tambien el del sider-width
 */
.header-logo {
    max-width: 250px;
    width: calc(100vw - ( 100vw * 0.78))!important;
}

/*
 * @component Header 
 * @description Cambia estilo del link para hacer login (Iniciar Sesion)
 */
.link-login {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 15px;
}


/*
 * @component Header 
 * @description Estilos del link (iniciar sesion) al hacer hover
 */
.link-login:hover {
    color: #bababa;
    animation: fadeIn linear 5s;
}


/*
 * @component Header -> InputSearch
 * @description Estilos de la barra de busqueda del header
 */
.header-search {
    display: flex;
    margin: 24px 10px;
    max-width: 437px;
}



/*
 * @component PAgeHeader 
 * @description Hace que el contenedor del title del pageheader abarque todo el espacio
 */
.custom-page-header .ant-page-header-heading .ant-page-header-heading-left,
.custom-page-header .ant-page-header-heading .ant-page-header-heading-left .ant-page-header-heading-title {
    width: 100%!important;
}

/*
 * @component PageHeader 
 * @description Hace que el texto sea de color blanco
 */
.custom-page-header .ant-typography{
    color: white;
    margin: 0 !important;
}

/*
 * @component PAgeHeader 
 * @description Hace que el contenedor del title del pageheader abarque todo el espacio
 */
 .custom-page-header .custom-page-header-breadcrumb .ant-breadcrumb-link,
 .custom-page-header .custom-page-header-breadcrumb .ant-breadcrumb-link a
 {
    color: rgba(255, 255, 255, 0.6) !important;
 }

 .custom-page-header .custom-page-header-breadcrumb .ant-breadcrumb-separator {
    color: rgba(255, 255, 255, 0.8) !important;
 }



/*
 * @component PAgeHeader -> extra 
 * @description Para el contenido en extra que sea texto plano
 */
 .custom-page-header .page-header-extra{
    color: #FFF;
    font-size: 11pt;
    font-weight: 600;
    line-height: 1.5;
   vertical-align: sub;
}

/*
 * @component Header -> Menu 
 * @description Alinea los elementos del contenido del header despues del logo
 */
.header-content {
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 100px;
}

/*
 * @component Header -> Menu 
 * @description Alinea los divs del contenedor de las opciones de usuario (notificaciones/logout)
 */
.header-options div {
    display: inline-block;
    margin: 5px 0;
    vertical-align: middle;
}


/*
 * @component Header -> Menu 
 * @description cambia el estilo del icono de notificaciones
 */
.header-options .icon-bell {
    color: #cccccc;
    font-size: 17pt;
    margin-bottom: -7PX;
}
/*
 * @component Header -> Menu 
 * @description cambia el estilo del bagde de notificaciones
 */
.header-options .ant-badge-count {
    background-color: #5e45d7;
}

/*
 * @component Header -> Menu 
 * @description Alinea los elementos del contenido del header despues del logo
 */
.header-content .dropdown-menu *{
    color: black
}

/*
 * @component Header -> InputSearch 
 * @description cambia el estilo input de busqueda del header 
 */

.input-global-search {
    flex: 1;
    max-width: 450px;
}



/*
 * @component Header   
 * @description Estilos del header para pantallas pequeñas
 */
@media (max-width: 768px) {
    .header-logo {
        background: #000000 !important;
        width: 100%;
        max-width: 100%;
        height: auto;
        position: relative;
    }
    .header-logo .logo {
        display: block;
        margin: auto;
    }
    .header-content {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        height: auto !important;
        padding-top: 1rem;
    }
    .header-content .header-search {
        order: 2;
        width: 100%;
        padding: 1rem 2.5rem 1rem 0;
    }
    .header-content .header-options {
        order: 1;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas medianas
 */
@media (max-width: 768px) {
    .header {
        display: contents !important;
        line-height: 1rem !important;
    }
    .header-logo {
        background: #000000;
    }
    .header .row-header-content {
        width: 100%;
        background: #000000;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas de medianas a largas
 */
@media (max-width: 1200px) and (min-width: 768px) {
    .header .header-logo .logo {
        min-width: auto;
        transition-duration: 1s !important;
    }
}



// /*
//  * @component HeaderPublic   
//  * @description Estilos del header public
//  */
// .header-public{
//     height: unset !important;
//     padding: 0 !important;
//     line-height: 40px  !important;


//     /*
//     * @component HeaderPublic   
//     * @description Todas las secciones van dentro de este elemento, para hacerlo responsivo en un futuro
//     */
//     .header-section-container{
//         max-width: 1300px;
//         width: 100%;
//         margin: 0 auto;
//         height: auto;
//     }

//     /*
//     * @component HeaderPublic   
//     * @description Esta es la barra de información, donde aparece la info de contacto
//     */
//     .information-bar{
//         color: white !important;
//         .link{
//             color: white !important;
//        }
//     }

//     /*
//     * @component Controlbar   
//     * @description barra de control
//     */
//     .control-bar{

//         /*
//         * @description CSS para la barra de busqueda de productos.
//         */
//         .search-bar{

//             /*
//             * @description INPUT de busqueda
//             */
//             .ant-input{
//                 background: white;
//                 padding: 12px 18px !important;
//                 border: none !important;

//                 &::placeholder{
//                     color: #ADADAD !important;                    
//                 }
//             }

//             /*
//             * @description Para el addon, qutiarle los bordes de antd
//             */
//             .ant-input-group-addon{
//                 padding: 0 !important;
//                 border: none !important;

//                 /*
//                 * @description El selector de catagorio
//                 */
//                 .ant-select {
//                     margin: 0 !important;
//                     border: none !important;


//                     /*
//                     * @description Para el selector interno del select
//                     */
//                     .ant-select-selector{
//                         padding-top: 4px;
//                         height: 49px;
//                         border: none !important;
//                     }
//                 }
//             }
//         }
//     }


//     /*
//     * @description Para la barrita blanca que contiene el carrito y la barra de catalogos
//     */
//     .menu-bar{
//         background-color: white;
//         font-weight: bold !important;
//     }
// }

/*
* @component Cotizaodr
* @description Esto es propio del cotizador
*/
.header-input{
    /*
    * @description INPUT de cantidad y de ID
    */
    &.ant-input,
    &.ant-input-number {
        border: 1px solid #C5C5C5;
        border-radius: 3px !important;
        background: none !important;
    }
}

/*
* @description Buton naranja del cotizador, el grandote
*/
.button-cotizador{
    border-radius: 150px !important;
    border: none !important;
    background-color: #E05447 !important;
    margin: 0 auto;
}


/*
* @description Para  la lista de productos, para hacer el span de error mas pequeño
*/
.list-item-producto{

    /*
    * @description EXPLAIN error
    */
    .ant-form-item-explain-error {
        font-size: 12px !important;
    }
}

