@import '../../../Styles/Public/variables.scss';


.como-participar {

    position: relative;
    // min-height: 600px;
    min-height: 70vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    &.background {
       background: linear-gradient(180deg, #F26513 0%, #F6645A 164.62%);
    }

    &-container {
        max-width: 1200px;
    }

    &-header {
        width: fit-content;
        z-index: 4;
        position: relative;
        margin-bottom: 25px !important;

        @media (max-width: $lg) {
            width: 100%;
        }
    }

    &-title {
        text-align: center;
        font-family: Anton, sans-serif;
        margin: 0 !important;

        @media (max-width: $lg) {
            text-align: center;
            display: block;
            width: 100%;


            &:nth-child(2) {
                position: relative;
                left: 0;
            }
        }

    }

    &-cards {
        display: flex;
        // max-width: 100;
        gap: 20px;

        @media (max-width: $lg) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // font-size: 20px;
            // flex-direction: column;
            // gap: 12px;
            // padding: 20px 50px;
        }


        .participar-card {
            justify-content: center;
            // align-content: center;
            // align-items: center;
            // width: 350px !important;
            width: 225px !important;

            &-title {
                font-size: 16px;
                text-align: center;
                color: #F26513;
            }

            &-description {
                text-align: center;
                max-width: 100% !important;
            }

            &-image {
                display: block;
                margin: 0 auto 12px auto;

            }
        }
    }

    &-content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 12px;


        @media (max-width: $lg) {
            // font-size: 36px;
            flex-direction: column;
        }
    }

    .image-left{
        position: relative;
        z-index: 4;
        width: 300px;
    }


    .participar-bg {
        position: absolute;
        left: 0;
        z-index: 0;
        // max-width: 100%;
        width: 100%;

        &.top {
            top: 0;
        }

        &.bottom {
            bottom: 0;
        }

        &.rect {
            bottom: 0;
            width: 100%;
            background-color: red;
        }
    }

    .lines {
        position: absolute;
        top: 0;
        right: 0;
    }

}
