@import '../mixins';
@import './variables.scss';


.slider-event {
    @include slider(100vw, 450px, true);
    z-index: 2;
}

.event-details {
    background: white;



}

.counter {
    background: $primary;

    padding: 16px 36px;
    
    position: absolute;
    bottom: 0;
    left: 50%;
    
    transform: translateX(-50%);
    
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 3;

    @media (max-width: $lg) {
        padding: 8px 18px !important;
        gap: 20px !important;
    }

    @media (max-width: $md) {
        gap: 10px !important;
        bottom: -100px;
    }


    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $lg) {
            flex: 1 1 calc(50% - 20px); 
        }

        @media (max-width: $md) {
            flex: 1 1 calc(50% - 10px); 
            // gap: 10px !important;            flex: 1 1 calc(50% - 20px); 
        }
        // gap: 10px;
    }

    &-label {
        color: white;
        font-size: 20px;

        @media (max-width: $lg) {
            font-size: 16px;
        }

        &-time {
            font-size: 48px;
            color: white;
            font-weight: bold;
            @media (max-width: $lg) {
                font-size: 24px;
            }
        }
    }

    &-divider {
        display: flex;
        width: 1px;
        background: white;

        @media (max-width: $lg) {
            display: none;
        }
    }
}

.event-details {
    position: relative;
}

.como-participar-evento {
    position: relative !important;
    overflow: hidden;
    bottom: 40px;

    @media (max-width: $lg) {
        padding-top: 100px !important;
    }

    @media (max-width: $md) {
        padding-top: 220px !important;
        // gap: 10px !important;            flex: 1 1 calc(50% - 20px); 
    }

    .text-white {
        color: #F26513 !important;
    }

    .como-participar {
        z-index: 4;
    }

    .circle {
        position: absolute;
        width: 500px;
        z-index: 1 !important;


        &-right {
            opacity: 0.3;
            top: 0;
            right: 0;
            transform:
                translateY(-50%) translateX(30%);
        }

        &-left {
            opacity: 0.3;
            bottom: 0;
            left: 0;
            transform:
                // translateY(-50%)
                translateX(-50%);
        }
    }
}

.premios {
    position: relative;
    bottom: 150px;


    &-content {
        max-width: 1200px;
        margin: 0 auto;
        z-index: 5;
        position: relative;
        padding-top: 250px;
    }

    &-title {

        text-align: center;
        margin-top: 0 !important;

        .orange {
            color: $primary !important;
        }
    }

    &-subtitle {
        text-align: center;
        font-weight: 400 !important;
        max-width: 700px;
        margin: 0 auto;
    }

    &-list {
        justify-content: center;
    }

    .premio-item {

        .ant-image {
            width: 100% !important;
            height: 300px !important;
            overflow: hidden;

        }

        &-image {

            object-fit: cover !important;
        }
    }


    .irregular {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        // height: 300px;
        // max-height: 250px !important;
    }
}


.orange-square {
    width: 12px;
    height: 12px;
    background: #F26513;
    display: inline-block;

}

.orange {
    color: #F26513;
}

.section-title {
    // text-align: center;
    font-family: Anton, sans-serif;
    // margin: 0 !important;
    // @media (max-width: $lg) {
    //     text-align: center;
    //     display: block;
    //     width: 100%;
    //     &:nth-child(2) {
    //         position: relative;
    //         left: 0;
    //     }
    // }
}

.faq {
    margin: 100px auto 80px auto;
    max-width: 992px;
    width: 100%;

    &-header {
        .section-title {}
    }



    .ant-collapse {
        //  rebeccapurple
        // border: 1px solid rgb(242 101 19) !important;//none !important;
        border: none !important;
    }

    .ant-collapse>.ant-collapse-item {
        // .faq .ant-collapse > .ant-collapse-item {
        // border-bottom: none !important;
        // }
        // border-bottom: 1px solid rgb(242 101 19) !important;
        border: 1px solid rgb(242 101 19) !important; //none !important;
        border-radius: 12px;
        margin-bottom: 12px;
    }

    .ant-collapse-content {
        background: none !important;
    }

    .ant-collapse-header {
        background-color: rgb(242 101 19 / 10%);
    }

}


.continuar-compra {
    background: $primary;
    color: white;
    margin-top: 12px;
    border-radius: 12px;
}

.boletos {

    .ant-menu {
        background: none !important;
    }
}