@import '../../Styles/mixins';
@import '../../Styles/Public/variables.scss';


#boletos {
    position: relative;
}

.not-available {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 5;
    backdrop-filter: blur(6px);

    .card-not {
        position: absolute;
        top: 50%;
        left: 50%;
        transform:
            translateY(-50%)
            translateX(-50%);

        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    }
}

.button-boleto {
    border-radius: 100px !important;
    background-color: #163b5a;
    color: white;
    border: none !important;
    padding: 1px 12px 0 12px;
    position: relative;

    &:hover {
        color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }

    .denied {
        position: absolute;
        top: -3px;
        right: -3px;
        color: #FF5500;
    }

    &.vendido {
        color: alpha($color: #000000);

        &:hover {
            color: adjust-color($color: #000000, $alpha: 0.40) !important;
            box-shadow: none !important;
        }
    }

    &.seleccionado {
        background: #F26513 !important;
    }
}


.selected-tickets {
    z-index: 5;
    width: 100%;
    bottom: 0px;
    left: 0;
    position: fixed;
    transition: all 0.3s ease-out;

    &-header {
        justify-content: center;
        align-content: center;
        margin-bottom: 0 !important;
        gap: 12px;
        cursor: pointer;
    }

    .ant-divider {
        margin: 12px 0;
    }

    &-wrapper {
        box-shadow: 0px 0px 10px rgba(19, 14, 14, 0.25);
        background: #F4F3F9;
        z-index: 4;
        max-width: 992px;
        margin: 0 auto;
        padding: 12px;
        text-align: center;
        border-radius: 12px 12px 0 0;
    }    
    &-tickets {
        @media (max-width: $md) {
            max-height: 160px;
            overflow: auto;
            // gap: 10px !important;
            // bottom: -100px;
        }

    }    
}



.boletos {
    z-index: 5;
}