/* Background effect. It covers the entire site */
.modal {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1000;
    /* Overlay effect: positioned over other containers */
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    position: fixed;
    /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto;
    /* Enable scroll if needed */
    padding-top: 80px;
    /* Location of the content container */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
}

/* Modal content */
.modal-content {
    background-color: transparent;
    width: 100%;
}

.modal-flex {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal-info {
    background-color: #888;
    border: 1px solid #888;
}

/* Close button */
.close {
    color: #ffffff;
    float: right;
    /* Positioned to the right of the parent container whichever size it is */
    font-size: 50px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.button {
    font-size: larger;
    border: none;
    outline: none;
    background: transparent;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    padding: 5px;
    cursor: pointer;
}

.roulette-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: +9;
    border-radius: 5rem;
    width: 7rem;
    height: 7rem;
    transform: translate(-55%, -55%);
    background: white;
    color: #F26513;
    font-size: 1.4rem;
    font-weight: bold;
}

.roulette-container {
    position: relative;
    width: 100%;
    max-width: 550px;
    aspect-ratio: 1;
    margin: 0 auto;
}

.roulette-container>div {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
}

.modal-spin{


    .ant-typography{
        color: #021c33
    }

    .ant-modal-content{
        background-color: transparent;
    }

    .ant-modal-body{
        border-radius: 20px;
        background-color: #FBEFE1;
    }
}