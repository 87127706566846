@import './variables.scss';

.btn-orange {
    background-color: #F26513 !important;
    border-radius: 2px !important;
    border-color: transparent !important;


    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}



#header-landing {
    position: fixed;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    transition: 
        background-color 300ms ease-in-out,
        padding 300ms ease-in-out,
        box-shadow 300ms ease-in-out
    ;
    padding: 20px 50px;


    @media (max-width: $md) {
        padding: 10px 25px;
    }

    &.ant-layout-header{
        background: unset;
        height: unset;
    }

    &.scrolling {
        background: rgba(0,0,0,1);
        padding: 6px 50px;

        
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
        @media (max-width: $md) {
            padding: 10px 25px;
        }
    }

    .logo-menu {
        height: 60px;
        @media (max-width: $md) {
            height: 40px;
        }

        @media (max-width: $sm) {
            height: 30px;
        }
    }

    .ant-menu {
        background: transparent !important;
    }

    .ant-menu-overflow-item {
        align-content: center;
    }

    .buttons-content {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 4px;
    }

    .open-drawer{
        display: none;

        @media (max-width: $xl) {
            display: block;
            
        }
    }

    .lg-menu {
        @media (max-width: $xl) {
            display: none;
        }
    }   

    .participar {
        @media (max-width: $sm) {
            display: none !important;
        }
    }
}

.drawer-menu{
    
    .text-white{
        color: black !important;
    }
}