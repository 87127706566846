.layout-content {
  background: #F4F8FB !important;
  max-height: calc(100vh - 100px) !important;
  height: calc(100vh - 100px) !important;
  min-height: calc(100vh - 100px) !important;
}

/*
 * @component Layout -> Content
 * @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla menos el tamaño del header, menos el tamaño del pageHeader
 */
 .admin-content {
  min-height: 88vh !important;
}
img[alt~="roulette-static"] { 
  position: absolute; 
  z-index: 5; 
  width: 20%;  
  right: 60px; 
  top: 0px; 
  content: url("/public/escorpion.svg");
  transform: rotate(40deg);
}
/*
 * @component ProjectManager -> Content
 * @description background color del content de la vista de project manager
 */
 .content-bg {
  
  background: #f0f2f5;

}

.dashboard-bg {
  background: 
  radial-gradient(
    farthest-side at top left,
    #FF01C71A, 
    transparent
  ),
  radial-gradient(
    farthest-corner at bottom right,
    #FF64331A, 
    transparent
  );

}

/*
 * @component Header 
 * @description Cambia el color del Header y la fuente
 */
 /* .ant-layout-header {
  color: #FFFFFF;
  line-height: 64px;
  background: #000;
} */

/*
 * @component PageHeader 
 * @description Cambia el color del PageHeader y la fuente, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #000 !important;
}

/*
 * @component PageHeader 
 * @description Cambia  la fuente del titulo del PageHeader, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */

 .ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 3px;
  color: #FFFFFF !important;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*
 * @component List->Header 
 * @description Cambia el texto a mayusculas del header de la lista
 */
 .header-list {
  text-transform: uppercase !important;
  padding: 12px;
}

/*
 * @component Tag 
 * @description Estilos del tag de Actualiza Saldo del modal de transacciones
 */
 .tag-actualiza-saldo{
  font-size: 12px;
  height: 20px!important;
  width: 98px;
  margin-bottom: 7px!important;
  display: block!important;
  line-height: 20px!important;
}


/*
 * @component Tag 
 * @description Estilos del tag de Actualiza Saldo del modal de transacciones
 */
.card-shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.04)!important;
}

.ant-menu-title-content{
  margin: 0 !important;
}


/*
 * @component Collapse 
 * @description Estilos para las listas de tipo collapse
 */
.collapse-item{
  margin-bottom: 1rem !important;
  background-color: white !important;
}

.collapse-item .ant-collapse-item{
  border:  none !important;

}

/*
 * @component TableContainerXML 
 * @description Esto es para el diseño de las tablas que permiten visualizar el XML
 */
.table-container {
    width: 100%;
    overflow: auto;
    max-height: 500px;
}

/*
 * @component TableContainerXML 
 * @description Para los divs-rows de la tabla
 */
.div-row {
    display: flex;
}


/*
 * @component TableContainerXML 
 * @description Para lso nombres de los elementos de la table
 */
.div-row .div-key {
    width: 25%;
    border-bottom: solid 1px black;
    background-color: #cccccc;
    border-right: solid 1px black;
    border-left: solid 1px black;
    padding-left: 0.5rem;
    min-width: 120px;
}

/*
 * @component TableContainerXML 
 * @description Para los titutlos de los elementos de la table XML
 */
.div-title {
    width: 100%;
    background-color: #000000;
    color: white;
    border-bottom: solid 1px black;
    padding-left: 1rem;
}

/*
 * @component TableContainerXML 
 * @description No se, preguntarle a manuel el lo hizo
 */
.div-string {
    border-bottom: solid 1px black;
    border-right: solid 1px black;
    width: 75%;
    padding-left: 1rem;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 200px;
}

/*
 * @component TableContainerXML 
 * @description Colores de los bordes de la tabla XML
 */
.factura-xml-table {
    border-left: solid 1px black;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}

/*
 * @component TableContainerXML 
 * @description para mantener la estructura de las posiciones de los elementos
 */
.table-container thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/*
 * @component TableContainerXML 
 * @description Mismo punto,pero para todo el TH global, no solamnete para headers
 */
.factura-xml-table th {
    text-transform: capitalize;
    padding-right: 2rem;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
    background-color: #cacaca;
}

/*
 * @component TableContainerXML 
 * @description Para los bordes de los TDS
 */
.factura-xml-table td {
    text-transform: capitalize;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}

.custom-checkbox-circle .ant-checkbox-checked::after {
  border-radius: 50px !important;
  border: 1.5px solid #2C3E50;
  height: 20px;
  width: 20px;
}

/*
* @component Checkbox 
* @description Acomoda el input dentro del checkbox
*/

.custom-checkbox-circle .ant-checkbox {
  left: -3px;
  top: -2px;
}

/*
* @component Checkbox 
* @description Hace el checkbox inner redondo
*/

.custom-checkbox-circle .ant-checkbox input, .custom-checkbox-circle .ant-checkbox .ant-checkbox-inner {
  border-radius: 50px;
  border: 1.5px solid #2C3E50;
  height: 20px;
  width: 20px;
}

/*
 * @component CardTaskClient -> List
 * @description Elimina el border-bottom del los items de la lista
 */

 .list-border-none ul .ant-list-item {
  border: none !important;
  padding: 12px 0 0 0 !important;
}

.ql-editor {
  min-height: 18em;
}

.ant-breadcrumb *{
  color: white !important;
}

.divider-modal{
  margin: 0 0 0 0 !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.right-align{
  text-align: right;
}

.info-title {
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

}

.info-data {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #979797;

}

.divider-title{
  font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}

.strong {
  font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

}

.text-green{
  color: #00F076
}


.customer-header-page{
  /* background-color: #dfdfdf; */
  border-radius: 20px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px !important;
}

.customer-header-page .ant-typography{
  margin: 0;
  color: #F36F2A;

}

.customer-body-page{
  /* background-color: #F2F2F2; */
  border-radius: 20px;
  padding: 1rem !important;
}

.customer-body-page .ant-card{
  background-color: transparent;
}

.btn-menu-header-customer,
.btn-menu-header-customer:hover,
.btn-menu-header-customer:focus{
  background-color: transparent;
  border: none;
}

.btn-menu-header-customer .ant-typography,
.btn-menu-header-customer .anticon {
  color: white;
  font-weight: bold;
}

.tag-boleto{
  position: relative;
}

.tag-boleto:hover{
  cursor: pointer;
}

.tag-boleto .anticon {
  position: absolute;
  color: red;
  font-size: 22px;
  right: 10%;
  z-index: -500;
}