.gradient-footer {
    background: linear-gradient(90deg, #0B1620 0%, #011C34 100%);
}

.footer {
    padding-top: 6rem;
}

.info-titulo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height */


    color: #FFFFFF;

}

.info-datos {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #FFFFFF;

}

.titulo-footer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    color: #EAA177;
}

.elementos-footer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 220.19%;
    /* or 40px */


    color: #FFFFFF;

    ul {
        margin-top: 1em !important;
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.card-suscribete {
    background: #474950;
    border: unset;
    border-radius: 0;
    padding: 2em;

    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */


        color: #FFFFFF;

    }

    .subtitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 220.19%;
        /* or 40px */


        color: #FFFFFF;
    }

    input {

        padding: 0.5em;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */


        color: #37393F;
    }

    button {
        background: #F26513;
        border-radius: 0;
        height: auto !important;
        padding: 0.56em;
        border: unset;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        color: #FFFFFF;
    }


}

.copyright {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 144.52%;
    /* identical to box height, or 26px */


    color: #FFFFFF;
}

.redes {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 144.52%;
    /* identical to box height, or 26px */


    color: #FFFFFF;
}

@media (min-width: 768px) and (max-width: 1382px) {

    .footer svg{
        width: 40px;
    }

    .info-titulo {
        font-size: 14px;
    }
    
    .info-datos {
        font-size: 12px;
    }
}

