.ticket {

    max-width: 350px;
    width: 100%;
    // height: 100px;
    background: #F5F7F9;
    align-self: center;
    margin: 10px auto;
    
    box-shadow: 0px 1px 9px 2px rgba(0,0,0,0.20);


    &-header {
        background: #F26513 !important;
        padding: 6px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-title,
    &-subtitle {
        font-family: Anton, sans-serif;
        margin-bottom: 0 !important;
        text-align: end;
    }

    &-content {
        padding: 6px 12px;
        position: relative;
        overflow: hidden;

        .markwater {
            position: absolute;
            top: 5%;
            left: 50%;
            opacity: 0.15;
            height: calc(100% - 10%);
            transform: translateX(-50%);
        }
    }

    &-banner {
        &-ribbon {
            background: #F26513 !important;
            padding: 6px;

            .ant-typography {
                font-size: 30px;
                text-align: center;
                color: black;
                font-family: Anton, sans-serif;
                margin-bottom:  0 !important;

            }
        }
        
        &-img {
            width: 100%;
        }
    }

    &-title {
        font-size: 22px;
    }

    &-subtitle {
        font-size: 10px;
        // font-size: 22px;
    }

    &-description {
        font-family: Anton, sans-serif;
        font-size: 12px;
        margin-bottom: 0 !important;
        color: #F26513 !important;

    }

    &-value {
        // font-family: Anton, sans-serif;
        font-size: 16px;
        margin-top: 0;
        font-weight: bold;
    }


    &s-container {
        background: white !important;
        min-height: 100%;
    }
}

.tickets-container{
    padding: 20px 0;
}